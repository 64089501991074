// THIS IS CREATED FOR 'Enigma' BY CHRISTIAN GRACE. IF YOU DON'T KNOW WHAT THAT IS, THIS ISN'T GOING TO BE OF USE TO YOU.

import React, { useState, useEffect } from 'react';

const CONSONANTS = 'BCDFGHJKLMNPQRSTVWXYZ'.split('');
const VOWELS = 'AEIOU'.split('');

// Using an existing compiled list from
// https://github.com/powerlanguage/word-lists/blob/master/1000-most-common-words.txt
const DICTIONARY = [
  'A',
  'ABLE',
  'ABOUT',
  'ABOVE',
  'ACT',
  'ADD',
  'AFRAID',
  'AFTER',
  'AGAIN',
  'AGAINST',
  'AGE',
  'AGO',
  'AGREE',
  'AIR',
  'ALL',
  'ALLOW',
  'ALSO',
  'ALWAYS',
  'AM',
  'AMONG',
  'AN',
  'AND',
  'ANGER',
  'ANIMAL',
  'ANSWER',
  'ANY',
  'APPEAR',
  'APPLE',
  'ARE',
  'AREA',
  'ARM',
  'ARRANGE',
  'ARRIVE',
  'ART',
  'AS',
  'ASK',
  'AT',
  'ATOM',
  'BABY',
  'BACK',
  'BAD',
  'BALL',
  'BAND',
  'BANK',
  'BAR',
  'BASE',
  'BASIC',
  'BAT',
  'BE',
  'BEAR',
  'BEAT',
  'BEAUTY',
  'BED',
  'BEEN',
  'BEFORE',
  'BEGAN',
  'BEGIN',
  'BEHIND',
  'BELIEVE',
  'BELL',
  'BEST',
  'BETTER',
  'BETWEEN',
  'BIG',
  'BIRD',
  'BIT',
  'BLACK',
  'BLOCK',
  'BLOOD',
  'BLOW',
  'BLUE',
  'BOARD',
  'BOAT',
  'BODY',
  'BONE',
  'BOOK',
  'BORN',
  'BOTH',
  'BOTTOM',
  'BOUGHT',
  'BOX',
  'BOY',
  'BRANCH',
  'BREAD',
  'BREAK',
  'BRIGHT',
  'BRING',
  'BROAD',
  'BROKE',
  'BROTHER',
  'BROUGHT',
  'BROWN',
  'BUILD',
  'BURN',
  'BUSY',
  'BUT',
  'BUY',
  'BY',
  'CALL',
  'CAME',
  'CAMP',
  'CAN',
  'CAPITAL',
  'CAPTAIN',
  'CAR',
  'CARD',
  'CARE',
  'CARRY',
  'CASE',
  'CAT',
  'CATCH',
  'CAUGHT',
  'CAUSE',
  'CELL',
  'CENT',
  'CENTER',
  'CENTURY',
  'CERTAIN',
  'CHAIR',
  'CHANCE',
  'CHANGE',
  'CHARACTER',
  'CHARGE',
  'CHART',
  'CHECK',
  'CHICK',
  'CHIEF',
  'CHILD',
  'CHILDREN',
  'CHOOSE',
  'CHORD',
  'CIRCLE',
  'CITY',
  'CLAIM',
  'CLASS',
  'CLEAN',
  'CLEAR',
  'CLIMB',
  'CLOCK',
  'CLOSE',
  'CLOTHE',
  'CLOUD',
  'COAST',
  'COAT',
  'COLD',
  'COLLECT',
  'COLONY',
  'COLOR',
  'COLUMN',
  'COME',
  'COMMON',
  'COMPANY',
  'COMPARE',
  'COMPLETE',
  'CONDITION',
  'CONNECT',
  'CONSIDER',
  'CONSONANT',
  'CONTAIN',
  'CONTINENT',
  'CONTINUE',
  'CONTROL',
  'COOK',
  'COOL',
  'COPY',
  'CORN',
  'CORNER',
  'CORRECT',
  'COST',
  'COTTON',
  'COULD',
  'COUNT',
  'COUNTRY',
  'COURSE',
  'COVER',
  'COW',
  'CREASE',
  'CREATE',
  'CROP',
  'CROSS',
  'CROWD',
  'CRY',
  'CURRENT',
  'CUT',
  'DAD',
  'DANCE',
  'DANGER',
  'DARK',
  'DAY',
  'DEAD',
  'DEAL',
  'DEAR',
  'DEATH',
  'DECIDE',
  'DECIMAL',
  'DEEP',
  'DEGREE',
  'DEPEND',
  'DESCRIBE',
  'DESERT',
  'DESIGN',
  'DETERMINE',
  'DEVELOP',
  'DICTIONARY',
  'DID',
  'DIE',
  'DIFFER',
  'DIFFICULT',
  'DIRECT',
  'DISCUSS',
  'DISTANT',
  'DIVIDE',
  'DIVISION',
  'DO',
  'DOCTOR',
  'DOES',
  'DOG',
  'DOLLAR',
  'DON\'T',
  'DONE',
  'DOOR',
  'DOUBLE',
  'DOWN',
  'DRAW',
  'DREAM',
  'DRESS',
  'DRINK',
  'DRIVE',
  'DROP',
  'DRY',
  'DUCK',
  'DURING',
  'EACH',
  'EAR',
  'EARLY',
  'EARTH',
  'EASE',
  'EAST',
  'EAT',
  'EDGE',
  'EFFECT',
  'EGG',
  'EIGHT',
  'EITHER',
  'ELECTRIC',
  'ELEMENT',
  'ELSE',
  'END',
  'ENEMY',
  'ENERGY',
  'ENGINE',
  'ENOUGH',
  'ENTER',
  'EQUAL',
  'EQUATE',
  'ESPECIALLY',
  'EVEN',
  'EVENING',
  'EVENT',
  'EVER',
  'EVERY',
  'EXACT',
  'EXAMPLE',
  'EXCEPT',
  'EXCITE',
  'EXERCISE',
  'EXPECT',
  'EXPERIENCE',
  'EXPERIMENT',
  'EYE',
  'FACE',
  'FACT',
  'FAIR',
  'FALL',
  'FAMILY',
  'FAMOUS',
  'FAR',
  'FARM',
  'FAST',
  'FAT',
  'FATHER',
  'FAVOR',
  'FEAR',
  'FEED',
  'FEEL',
  'FEET',
  'FELL',
  'FELT',
  'FEW',
  'FIELD',
  'FIG',
  'FIGHT',
  'FIGURE',
  'FILL',
  'FINAL',
  'FIND',
  'FINE',
  'FINGER',
  'FINISH',
  'FIRE',
  'FIRST',
  'FISH',
  'FIT',
  'FIVE',
  'FLAT',
  'FLOOR',
  'FLOW',
  'FLOWER',
  'FLY',
  'FOLLOW',
  'FOOD',
  'FOOT',
  'FOR',
  'FORCE',
  'FOREST',
  'FORM',
  'FORWARD',
  'FOUND',
  'FOUR',
  'FRACTION',
  'FREE',
  'FRESH',
  'FRIEND',
  'FROM',
  'FRONT',
  'FRUIT',
  'FULL',
  'FUN',
  'GAME',
  'GARDEN',
  'GAS',
  'GATHER',
  'GAVE',
  'GENERAL',
  'GENTLE',
  'GET',
  'GIRL',
  'GIVE',
  'GLAD',
  'GLASS',
  'GO',
  'GOLD',
  'GONE',
  'GOOD',
  'GOT',
  'GOVERN',
  'GRAND',
  'GRASS',
  'GRAY',
  'GREAT',
  'GREEN',
  'GREW',
  'GROUND',
  'GROUP',
  'GROW',
  'GUESS',
  'GUIDE',
  'GUN',
  'HAD',
  'HAIR',
  'HALF',
  'HAND',
  'HAPPEN',
  'HAPPY',
  'HARD',
  'HAS',
  'HAT',
  'HAVE',
  'HE',
  'HEAD',
  'HEAR',
  'HEARD',
  'HEART',
  'HEAT',
  'HEAVY',
  'HELD',
  'HELP',
  'HER',
  'HERE',
  'HIGH',
  'HILL',
  'HIM',
  'HIS',
  'HISTORY',
  'HIT',
  'HOLD',
  'HOLE',
  'HOME',
  'HOPE',
  'HORSE',
  'HOT',
  'HOUR',
  'HOUSE',
  'HOW',
  'HUGE',
  'HUMAN',
  'HUNDRED',
  'HUNT',
  'HURRY',
  'I',
  'ICE',
  'IDEA',
  'IF',
  'IMAGINE',
  'IN',
  'INCH',
  'INCLUDE',
  'INDICATE',
  'INDUSTRY',
  'INSECT',
  'INSTANT',
  'INSTRUMENT',
  'INTEREST',
  'INVENT',
  'IRON',
  'IS',
  'ISLAND',
  'IT',
  'JOB',
  'JOIN',
  'JOY',
  'JUMP',
  'JUST',
  'KEEP',
  'KEPT',
  'KEY',
  'KILL',
  'KIND',
  'KING',
  'KNEW',
  'KNOW',
  'LADY',
  'LAKE',
  'LAND',
  'LANGUAGE',
  'LARGE',
  'LAST',
  'LATE',
  'LAUGH',
  'LAW',
  'LAY',
  'LEAD',
  'LEARN',
  'LEAST',
  'LEAVE',
  'LED',
  'LEFT',
  'LEG',
  'LENGTH',
  'LESS',
  'LET',
  'LETTER',
  'LEVEL',
  'LIE',
  'LIFE',
  'LIFT',
  'LIGHT',
  'LIKE',
  'LINE',
  'LIQUID',
  'LIST',
  'LISTEN',
  'LITTLE',
  'LIVE',
  'LOCATE',
  'LOG',
  'LONE',
  'LONG',
  'LOOK',
  'LOST',
  'LOT',
  'LOUD',
  'LOVE',
  'LOW',
  'MACHINE',
  'MADE',
  'MAGNET',
  'MAIN',
  'MAJOR',
  'MAKE',
  'MAN',
  'MANY',
  'MAP',
  'MARK',
  'MARKET',
  'MASS',
  'MASTER',
  'MATCH',
  'MATERIAL',
  'MATTER',
  'MAY',
  'ME',
  'MEAN',
  'MEANT',
  'MEASURE',
  'MEAT',
  'MEET',
  'MELODY',
  'MEN',
  'METAL',
  'METHOD',
  'MIDDLE',
  'MIGHT',
  'MILE',
  'MILK',
  'MILLION',
  'MIND',
  'MINE',
  'MINUTE',
  'MISS',
  'MIX',
  'MODERN',
  'MOLECULE',
  'MOMENT',
  'MONEY',
  'MONTH',
  'MOON',
  'MORE',
  'MORNING',
  'MOST',
  'MOTHER',
  'MOTION',
  'MOUNT',
  'MOUNTAIN',
  'MOUTH',
  'MOVE',
  'MUCH',
  'MULTIPLY',
  'MUSIC',
  'MUST',
  'MY',
  'NAME',
  'NATION',
  'NATURAL',
  'NATURE',
  'NEAR',
  'NECESSARY',
  'NECK',
  'NEED',
  'NEIGHBOR',
  'NEVER',
  'NEW',
  'NEXT',
  'NIGHT',
  'NINE',
  'NO',
  'NOISE',
  'NOON',
  'NOR',
  'NORTH',
  'NOSE',
  'NOTE',
  'NOTHING',
  'NOTICE',
  'NOUN',
  'NOW',
  'NUMBER',
  'NUMERAL',
  'OBJECT',
  'OBSERVE',
  'OCCUR',
  'OCEAN',
  'OF',
  'OFF',
  'OFFER',
  'OFFICE',
  'OFTEN',
  'OH',
  'OIL',
  'OLD',
  'ON',
  'ONCE',
  'ONE',
  'ONLY',
  'OPEN',
  'OPERATE',
  'OPPOSITE',
  'OR',
  'ORDER',
  'ORGAN',
  'ORIGINAL',
  'OTHER',
  'OUR',
  'OUT',
  'OVER',
  'OWN',
  'OXYGEN',
  'PAGE',
  'PAINT',
  'PAIR',
  'PAPER',
  'PARAGRAPH',
  'PARENT',
  'PART',
  'PARTICULAR',
  'PARTY',
  'PASS',
  'PAST',
  'PATH',
  'PATTERN',
  'PAY',
  'PEOPLE',
  'PERHAPS',
  'PERIOD',
  'PERSON',
  'PHRASE',
  'PICK',
  'PICTURE',
  'PIECE',
  'PITCH',
  'PLACE',
  'PLAIN',
  'PLAN',
  'PLANE',
  'PLANET',
  'PLANT',
  'PLAY',
  'PLEASE',
  'PLURAL',
  'POEM',
  'POINT',
  'POOR',
  'POPULATE',
  'PORT',
  'POSE',
  'POSITION',
  'POSSIBLE',
  'POST',
  'POUND',
  'POWER',
  'PRACTICE',
  'PREPARE',
  'PRESENT',
  'PRESS',
  'PRETTY',
  'PRINT',
  'PROBABLE',
  'PROBLEM',
  'PROCESS',
  'PRODUCE',
  'PRODUCT',
  'PROPER',
  'PROPERTY',
  'PROTECT',
  'PROVE',
  'PROVIDE',
  'PULL',
  'PUSH',
  'PUT',
  'QUART',
  'QUESTION',
  'QUICK',
  'QUIET',
  'QUITE',
  'QUOTIENT',
  'RACE',
  'RADIO',
  'RAIL',
  'RAIN',
  'RAISE',
  'RAN',
  'RANGE',
  'RATHER',
  'REACH',
  'READ',
  'READY',
  'REAL',
  'REASON',
  'RECEIVE',
  'RECORD',
  'RED',
  'REGION',
  'REMEMBER',
  'REPEAT',
  'REPLY',
  'REPRESENT',
  'REQUIRE',
  'REST',
  'RESULT',
  'RICH',
  'RIDE',
  'RIGHT',
  'RING',
  'RISE',
  'RIVER',
  'ROAD',
  'ROCK',
  'ROLL',
  'ROOM',
  'ROOT',
  'ROPE',
  'ROSE',
  'ROUND',
  'ROW',
  'RUB',
  'RULE',
  'RUN',
  'SAFE',
  'SAID',
  'SAIL',
  'SALT',
  'SAME',
  'SAND',
  'SAT',
  'SAVE',
  'SAW',
  'SAY',
  'SCALE',
  'SCHOOL',
  'SCIENCE',
  'SCORE',
  'SEA',
  'SEARCH',
  'SEASON',
  'SEAT',
  'SECOND',
  'SECTION',
  'SEE',
  'SEED',
  'SEEM',
  'SEGMENT',
  'SELECT',
  'SELF',
  'SELL',
  'SEND',
  'SENSE',
  'SENT',
  'SENTENCE',
  'SEPARATE',
  'SERVE',
  'SET',
  'SETTLE',
  'SEVEN',
  'SEVERAL',
  'SHALL',
  'SHAPE',
  'SHARE',
  'SHARP',
  'SHE',
  'SHEET',
  'SHELL',
  'SHINE',
  'SHIP',
  'SHOE',
  'SHOP',
  'SHORE',
  'SHORT',
  'SHOULD',
  'SHOULDER',
  'SHOUT',
  'SHOW',
  'SIDE',
  'SIGHT',
  'SIGN',
  'SILENT',
  'SILVER',
  'SIMILAR',
  'SIMPLE',
  'SINCE',
  'SING',
  'SINGLE',
  'SISTER',
  'SIT',
  'SIX',
  'SIZE',
  'SKILL',
  'SKIN',
  'SKY',
  'SLAVE',
  'SLEEP',
  'SLIP',
  'SLOW',
  'SMALL',
  'SMELL',
  'SMILE',
  'SNOW',
  'SO',
  'SOFT',
  'SOIL',
  'SOLDIER',
  'SOLUTION',
  'SOLVE',
  'SOME',
  'SON',
  'SONG',
  'SOON',
  'SOUND',
  'SOUTH',
  'SPACE',
  'SPEAK',
  'SPECIAL',
  'SPEECH',
  'SPEED',
  'SPELL',
  'SPEND',
  'SPOKE',
  'SPOT',
  'SPREAD',
  'SPRING',
  'SQUARE',
  'STAND',
  'STAR',
  'START',
  'STATE',
  'STATION',
  'STAY',
  'STEAD',
  'STEAM',
  'STEEL',
  'STEP',
  'STICK',
  'STILL',
  'STONE',
  'STOOD',
  'STOP',
  'STORE',
  'STORY',
  'STRAIGHT',
  'STRANGE',
  'STREAM',
  'STREET',
  'STRETCH',
  'STRING',
  'STRONG',
  'STUDENT',
  'STUDY',
  'SUBJECT',
  'SUBSTANCE',
  'SUBTRACT',
  'SUCCESS',
  'SUCH',
  'SUDDEN',
  'SUFFIX',
  'SUGAR',
  'SUGGEST',
  'SUIT',
  'SUMMER',
  'SUN',
  'SUPPLY',
  'SUPPORT',
  'SURE',
  'SURFACE',
  'SURPRISE',
  'SWIM',
  'SYLLABLE',
  'SYMBOL',
  'SYSTEM',
  'TABLE',
  'TAIL',
  'TAKE',
  'TALK',
  'TALL',
  'TEACH',
  'TEAM',
  'TEETH',
  'TELL',
  'TEMPERATURE',
  'TEN',
  'TERM',
  'TEST',
  'THAN',
  'THANK',
  'THAT',
  'THE',
  'THEIR',
  'THEM',
  'THEN',
  'THERE',
  'THESE',
  'THEY',
  'THICK',
  'THIN',
  'THING',
  'THINK',
  'THIRD',
  'THIS',
  'THOSE',
  'THOUGH',
  'THOUGHT',
  'THOUSAND',
  'THREE',
  'THROUGH',
  'THROW',
  'THUS',
  'TIE',
  'TIME',
  'TINY',
  'TIRE',
  'TO',
  'TOGETHER',
  'TOLD',
  'TONE',
  'TOO',
  'TOOK',
  'TOOL',
  'TOP',
  'TOTAL',
  'TOUCH',
  'TOWARD',
  'TOWN',
  'TRACK',
  'TRADE',
  'TRAIN',
  'TRAVEL',
  'TREE',
  'TRIANGLE',
  'TRIP',
  'TROUBLE',
  'TRUCK',
  'TRUE .',
  'TRY',
  'TUBE',
  'TURN',
  'TWENTY',
  'TWO',
  'TYPE',
  'UNDER',
  'UNIT',
  'UNTIL',
  'UP',
  'US',
  'USE',
  'USUAL',
  'VALLEY',
  'VALUE',
  'VARY',
  'VERB',
  'VERY',
  'VIEW',
  'VILLAGE',
  'VISIT',
  'VOICE',
  'VOWEL',
  'WAIT',
  'WALK',
  'WALL',
  'WANT',
  'WAR',
  'WARM',
  'WAS',
  'WASH',
  'WATCH',
  'WATER',
  'WAVE',
  'WAY',
  'WE',
  'WEAR',
  'WEATHER',
  'WEEK',
  'WEIGHT',
  'WELL',
  'WENT',
  'WERE',
  'WEST',
  'WHAT',
  'WHEEL',
  'WHEN',
  'WHERE',
  'WHETHER',
  'WHICH',
  'WHILE',
  'WHITE',
  'WHO',
  'WHOLE',
  'WHOSE',
  'WHY',
  'WIDE',
  'WIFE',
  'WILD',
  'WILL',
  'WIN',
  'WIND',
  'WINDOW',
  'WING',
  'WINTER',
  'WIRE',
  'WISH',
  'WITH',
  'WOMAN',
  'WOMEN',
  'WON\'T',
  'WONDER',
  'WOOD',
  'WORD',
  'WORK',
  'WORLD',
  'WOULD',
  'WRITE',
  'WRITTEN',
  'WRONG',
  'WROTE',
  'YARD',
  'YEAR',
  'YELLOW',
  'YES',
  'YET',
  'YOU',
  'YOUNG',
  'YOUR'
];

const LEFT_WORDS = ['LIMA', 'LARRY', 'LARSON', 'LORRY'];
const RIGHT_WORDS = ['ROMEO', 'ROY', 'RYAN', 'RORY'];

const STRAIGHT = 'AEFHIKLMNTVWXYZ'.split('')
const MIXED = 'BDGJPQRU'.split('')
const CURVED = 'COS'

const getLetterType = (letter: string): 'STRAIGHT' | 'MIXED' | 'CURVED' => {
  if (STRAIGHT.includes(letter.toUpperCase())) return 'STRAIGHT';
  if (MIXED.includes(letter.toUpperCase())) return 'MIXED';
  if (CURVED.includes(letter.toUpperCase())) return 'CURVED';
  return 'STRAIGHT'; // Default to STRAIGHT if not found
};

const getRandomWord = (wordList: string[]): string => wordList[Math.floor(Math.random() * wordList.length)];

interface TileProps {
  letter: string;
  onDragStart: (e: React.DragEvent<HTMLDivElement>, letter: string) => void;
  onClick: (letter: string) => void;
}

const Tile: React.FC<TileProps> = ({ letter, onDragStart, onClick }) => (
  <div
    draggable
    onDragStart={(e) => onDragStart(e, letter)}
    onClick={() => onClick(letter)}
    className="w-10 h-10 bg-blue-500 text-white flex items-center justify-center m-1 cursor-pointer select-none hover:bg-blue-600"
  >
    {letter}
  </div>
);

interface BoardProps {
  word: string[];
  onDrop: (letter: string) => void;
}

const Board: React.FC<BoardProps> = ({ word, onDrop }) => {
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const letter = e.dataTransfer.getData('text');
    onDrop(letter);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className="w-full min-h-20 bg-gray-200 flex items-center justify-start p-2 border-2 border-dashed border-gray-400 mb-4 flex-wrap"
    >
      {word.map((letter, index) => (
        <div
          key={index}
          className="w-10 h-10 bg-green-500 text-white flex items-center justify-center m-1"
        >
          {letter}
        </div>
      ))}
    </div>
  );
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  return (
    <div 
      className={`fixed inset-0 flex items-center justify-center z-50 transition-all duration-300 ${
        isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
      }`}
      style={{
        backgroundColor: isOpen ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
        transition: 'background-color 300ms, opacity 300ms, visibility 300ms'
      }}
    >
      <div 
        className={`bg-white p-8 rounded-lg shadow-lg transition-all duration-300 ease-in-out ${
          isOpen ? 'scale-100 opacity-100' : 'scale-90 opacity-0'
        }`}
      >
        {children}
      </div>
    </div>
  );
};

const generateCodeword = (word: string, consonantsLeft: boolean): string => {
  let codeword = '';
  for (let i = 0; i < word.length; i++) {
    const letter = word[i];
    const isConsonant = CONSONANTS.includes(letter.toUpperCase());
    const direction = (consonantsLeft && isConsonant) || (!consonantsLeft && !isConsonant)
      ? getRandomWord(LEFT_WORDS)
      : getRandomWord(RIGHT_WORDS);

    const possibleWords = DICTIONARY.filter(dictWord => 
      dictWord.length > i && dictWord[i].toUpperCase() === direction[0]
    );

    if (possibleWords.length > 0) {
      codeword += possibleWords[Math.floor(Math.random() * possibleWords.length)] + ' ';
    } else {
      codeword += direction + ' ';
    }
  }

  const codewordArray = codeword.trim().split(' ');
  
  // Add words based on the type of the first letter if there's space
  if (codewordArray.length < 10) {
    const firstLetterType = getLetterType(word[0]);
    const typeLetters = firstLetterType === 'STRAIGHT' ? STRAIGHT :
                        firstLetterType === 'MIXED' ? MIXED : CURVED;
    
    const typeWords = DICTIONARY.filter(dictWord => 
      typeLetters.includes(dictWord[0].toUpperCase()) && dictWord.length <= 4 // Also checks to see if this word is four letters or less
    );

    if (typeWords.length > 0) {
      codewordArray.push(typeWords[Math.floor(Math.random() * typeWords.length)]);
    }
  }

  // Fill remaining slots with random words that are four or less letters long
  while (codewordArray.length < 10) {
    const newWord = DICTIONARY[Math.floor(Math.random() * DICTIONARY.length)];
    if (newWord.length <= 4) {
      codewordArray.push(newWord);
    }
  }

  return codewordArray.join(' ').trim();
};

const LetterTileGame: React.FC = () => {
  const [word, setWord] = useState<string[]>([]);
  const [consonantsLeft, setConsonantsLeft] = useState<boolean>(true);
  const [security, setSecurity] = useState<number>(0);
  const [codeword, setCodeword] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, letter: string) => {
    e.dataTransfer.setData('text', letter);
  };

  const handleAddLetter = (letter: string) => {
    setWord((prev) => {
      const newWord = [...prev, letter];
      updateSecurity(newWord.length);
      return newWord;
    });
  };

  const handleSwap = () => {
    setConsonantsLeft((prev) => !prev);
  };

  const handleReset = () => {
    setWord([]);
    setCodeword('');
    setSecurity(0);
  };

  const updateSecurity = (wordLength: number) => {
    setSecurity((prevSecurity) => {
      const baseIncrease = Math.min(5 + wordLength, 20);
      const randomFactor = 0.5 + Math.random();
      const increase = baseIncrease * randomFactor;
      return Math.min(Math.round(prevSecurity + increase), 100);
    });
  };

  const handleGenerateCodeword = () => {
    const newCodeword = generateCodeword(word.join(''), consonantsLeft);
    setCodeword(newCodeword);
    setIsCopied(false);
    setTimeout(() => setIsModalOpen(true), 0);
  };

  const handleCopyCodeword = () => {
    navigator.clipboard.writeText(codeword).then(() => {
      setIsCopied(true);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleButtonClick = () => {
    if (isCopied) {
      setIsModalOpen(false);
    } else {
      handleCopyCodeword();
    }
  };

  useEffect(() => {
    if (codeword) {
      setIsModalOpen(true);
    }
  }, [codeword]);

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Codeword Generator</h1>
      <div className="flex justify-between mb-4">
        <div>
          <h2 className="text-lg font-semibold mb-2">
            {consonantsLeft ? 'Consonants' : 'Vowels'}
          </h2>
          <div className="flex flex-wrap">
            {(consonantsLeft ? CONSONANTS : VOWELS).map((letter) => (
              <Tile 
                key={letter} 
                letter={letter} 
                onDragStart={handleDragStart} 
                onClick={handleAddLetter}
              />
            ))}
          </div>
        </div>
        <div>
          <h2 className="text-lg font-semibold mb-2">
            {consonantsLeft ? 'Vowels' : 'Consonants'}
          </h2>
          <div className="flex flex-wrap">
            {(consonantsLeft ? VOWELS : CONSONANTS).map((letter) => (
              <Tile 
                key={letter} 
                letter={letter} 
                onDragStart={handleDragStart} 
                onClick={handleAddLetter}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex space-x-4 mb-4">
        <button
          onClick={handleSwap}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        >
          Swap Consonants and Vowels
        </button>
        <button
          onClick={handleReset}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Reset Word
        </button>
      </div>
      <Board word={word} onDrop={handleAddLetter} />
      <div className="mb-4">
        <h2 className="text-lg font-semibold mb-2">Codeword Security:</h2>
        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div 
            className={`h-2.5 rounded-full ${security < 50 ? 'bg-red-600' : 'bg-green-600'}`} 
            style={{width: `${security}%`}}
          ></div>
        </div>
        <p className="mt-2">{security}% Secure</p>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={handleGenerateCodeword}
          disabled={word.length === 0}
          className={`px-4 py-2 rounded ${
            word.length > 0
              ? 'bg-blue-500 text-white hover:bg-blue-600'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
        >
          Generate Codeword
        </button>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-xl font-bold mb-4 text-center">Your codeword is:</h2>
        <div className="bg-gray-100 p-4 rounded-md mb-4">
          <code className="text-sm font-mono break-all">{codeword}</code>
        </div>
        <div className="flex justify-center mt-4">
          <button 
            onClick={handleButtonClick}
            className={`px-4 py-2 text-white rounded transition-all duration-300 ${
              isCopied 
                ? 'bg-red-500 hover:bg-red-600 w-24' 
                : 'bg-blue-500 hover:bg-blue-600 w-32'
            }`}
          >
            {isCopied ? 'Close' : 'Copy'}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default LetterTileGame;